import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Typefaces from "../components/typefaces"

import { Row, Col, Image } from "react-bootstrap"

//image imports
import DarkGalleries1 from "./../images/the dark galleries/fp-the dark galleries.jpg"
import DarkGalleries2 from "./../images/the dark galleries/the dark galleries1.jpg"
import DarkGalleries3 from "./../images/the dark galleries/the dark galleries2.jpg"
import DarkGalleries4 from "./../images/the dark galleries/the dark galleries3.jpg"

const TheDarkGalleries = ({location, id}) => (
    <Layout>
        <Seo title="The Dark Galleries" description="The Dark Galleries, by Manon Lambeens" />
        <div
        style={{
          
        }}
      >
        <Row className="">
            <Col xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-1">
              <h1 className="text-uppercase fs-4 text-dark">the dark galleries</h1>
                <p className="textAbout">
                    This project is a museum guide. The exhibition was themed film-noir. This combined with the design being based upon a newspaper ended up with this result.
                    
                    The newspaper theme can be found within the 12/12 layout combined with the folded lines. The guide being printed in black and white makes sure the result fitted the film-noir theme.
                    </p>
                <Typefaces types={['American Typewriter bold', 'Helvetica regular']} />
        </Col>
      </Row>
      <Row className="">
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={DarkGalleries1} fluid alt="The Dark Galleries, Manon Lambeens"/>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} s={6} xs={12} className="pe-1 ps-1 mt-2">
         <Image src={DarkGalleries2} fluid alt="The Dark Galleries, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={DarkGalleries3} fluid style={{width: '100%'}} alt="The Dark Galleries, Manon Lambeens"/>
        </Col>
      </Row>
      <Row className="">
        <Col  xxl={12} xl={12} lg={12} md={12} s={12} xs={12} className="pe-1 ps-1 mt-2">
          <Image src={DarkGalleries4} fluid style={{width: '100%'}} alt="The Dark Galleries, Manon Lambeens"/>
        </Col>
      </Row>
      </div>
    </Layout>
)

export default TheDarkGalleries;